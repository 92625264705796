<template
  ><svg
    class="w-20 h-auto"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 324 295"
  >
    <path
      d="M245.387 126.661a162.68 162.68 0 0 0-5.306-12.103c-3.339-6.83-11.572-9.677-18.402-6.326-6.83 3.335-9.668 11.572-6.329 18.395 6.94 14.212 10.334 26.627 9.812 35.907l-.02.768c0 29.681-24.158 53.824-53.852 53.824-29.694 0-53.852-24.143-53.852-53.824l-.024-.775c-1.085-19.348 14.641-50.874 43.14-86.496 4.75-5.932 3.786-14.587-2.148-19.335-5.937-4.747-14.593-3.78-19.34 2.151-20.053 25.06-43.051 59.413-48.137 90.002.82-15.502 3.665-33.68 8.552-53.755 1.799-7.38-2.731-14.817-10.114-16.61-7.4-1.826-14.83 2.723-16.625 10.109-6.277 25.773-11.682 58.15-8.69 85.481-3.535-13.88-6.229-30.182-7.88-48.428-.682-7.561-7.365-13.177-14.942-12.463-7.568.689-13.152 7.375-12.466 14.943 2.499 27.65 6.998 50.771 13.42 69.289-5.368-10.209-10.612-21.787-15.596-34.576-2.762-7.074-10.732-10.58-17.822-7.822-7.077 2.755-10.581 10.731-7.825 17.808 12.73 32.655 30.919 76.497 57.912 94.623 20.774 17.156 47.407 27.468 76.462 27.468 66.376 0 120.183-53.783 120.183-120.129 0-17.128-3.641-33.385-10.111-48.126Z"
      :fill="colorStyle.lightBlue"
    />
    <path
      d="M275.225 78.95c15.382 0 27.899-12.512 27.899-27.887.872-15.612-17.761-39.766-27.899-51.063-10.142 11.293-28.774 35.427-27.909 50.836.007 15.602 12.523 28.113 27.909 28.113Z"
      :fill="colorStyle.deepBlue"
    />
    <path
      d="M293.676 81.815c-5.402 3.242-11.7 5.146-18.451 5.146-19.597 0-35.549-15.77-35.889-35.272a106.717 106.717 0 0 0-22.188-2.33c-16.511 0-32.144 3.757-46.092 10.46a22.867 22.867 0 0 1 1.205 5.125c.666 5.993-1.044 11.887-4.812 16.597-26.763 33.444-42.173 63.533-41.222 80.493l.038 1.008c0 25.074 20.197 45.262 45.024 45.262 24.828 0 45.025-20.188 45.025-45.001l.024-1.002c.44-7.927-2.732-19.128-8.92-31.804-2.649-5.416-3.03-11.543-1.063-17.245 1.963-5.705 6.03-10.301 11.452-12.95a22.378 22.378 0 0 1 17.222-1.077c5.718 1.959 10.327 6.031 12.984 11.465a168.916 168.916 0 0 1 5.529 12.59c7.155 16.363 10.783 33.69 10.783 51.508 0 33.286-12.688 63.663-33.476 86.565 52.342-6.723 92.799-51.395 92.799-105.542 0-28.761-11.429-54.84-29.972-73.996Z"
      :fill="colorStyle.brown"
    /></svg
></template>
<script>
  export default {
    data() {
      return {
        colors: [
          {
            deepBlue: "#1e458b",
            lightBlue: "#9eddf9",
            brown: "#a2502d",
            copy: "#231f20",
          },
          {
            deepBlue: "#585757",
            lightBlue: "#e4e3e2",
            brown: "#b2b0b0",
            copy: "#231f20",
          },
        ],
      };
    },
    props: {
      colorMode: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      colorStyle() {
        if (this.colorMode === true) {
          return this.colors[0];
        } else {
          return this.colors[1];
        }
      },
    },
  };
</script>
