<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav class="bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <!--
            <img
              class="block lg:hidden h-8 w-auto"
              src="../assets/aluseau.svg"
              alt="Aluseau"
            />
            <img
              class="hidden lg:block h-8 w-auto"
              src="../assets/aluseau.svg"
              alt="Aluseau"
            />
            -->
            <Logo :colorMode="true" />
          </div>
          <div class="hidden sm:ml-6 md:flex md:space-x-8">
            <!-- Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
            <a
              href="#mission"
              v-smooth-scroll
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Mission
            </a>
            <a
              href="#membres"
              v-smooth-scroll
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Membres
            </a>
            <a
              href="#conseil"
              v-smooth-scroll
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Conseil d'administration
            </a>
            <a
              href="#groupes"
              v-smooth-scroll
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Groupes de travail
            </a>
            <a
              href="#partenaires"
              v-smooth-scroll
              class="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
            >
              Partenaires
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import Logo from "@/components/Logo.vue";
  export default {
    components: {
      Logo,
    },
  };
</script>

<style></style>
