import Vue from "vue";
import App from "./App.vue";
import "./assets/index.css";
import i18n from "./i18n";

Vue.config.productionTip = true;

import PrismicVue from "prismic-vue";

Vue.use(PrismicVue, {
  endpoint: "",
  linkResolver: function(doc) {
    if (doc.type === "home") {
      return "/";
    }
  },
});

import VueSmoothScroll from "vue2-smooth-scroll";
Vue.use(VueSmoothScroll);

new Vue({
  i18n,
  render: (h) => h(App),
  /*mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event("render-event"));
  },*/
}).$mount("#app");
